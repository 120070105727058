import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const BootstrapSelect = styled(Select)(({ theme }) => ({
  borderRadius: 8,
  position: "relative",
  backgroundColor: "white",
  // border: '1px solid',
  borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
  fontSize: "15px",
  width: "525px",
  maxHeight: "13px",
  padding: "22px 12px",
  "& .MuiSelect-select": {
    padding: "0",
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
  },
  "& .MuiSelect-icon": {
    right: "12px", // Aligns the dropdown icon
  },
  "&::placeholder": {
    fontFamily: "mundial",
    fontSize: "8px !important",
  },
  "& .MuiMenuItem-root": {
    fontSize: "15px",
  },
}));

type SelectDropdownProps = {
  title?: string;
  iD?: string;
  placeholder: string;
  errors?: any;
  sx?: any;
  field: any | null;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
  options: { value: string | number; label: string }[];
};

const SelectDropdown = ({
  iD = "",
  placeholder,
  errors,
  title = "",
  sx,
  field,
  isDisabled = false,
  onChange,
  options,
}: SelectDropdownProps) => {
  return (
    <Box sx={{ marginY: 2 }}>
      <InputLabel
        shrink
        htmlFor={iD}
        sx={{
          fontWeight: 300,
          "&.Mui-focused": {
            color: "#00000099",
          },
        }}
      >
        <Typography
          variant="body2"
          sx={{
            my: "0.2vh",
          }}
        >
          {title}
          <span style={{ color: "red", marginLeft: "2px" }}>*</span>
        </Typography>
      </InputLabel>

      <FormControl fullWidth>
        <BootstrapSelect
          disabled={isDisabled}
          id={iD}
          onChange={onChange}
          {...field}
          displayEmpty
          inputProps={{ "aria-label": placeholder }}
          sx={sx}
        >
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </BootstrapSelect>
      </FormControl>

      {errors && (
        <FormHelperText error>
          <Typography variant="body2" sx={{ color: "red", paddingLeft: 1 }}>
            {errors[iD]?.message}
          </Typography>
        </FormHelperText>
      )}
    </Box>
  );
};

export default SelectDropdown;
