import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getDealsDataById, getUserProfile } from "../../../../api/services";
import { dealtransaction } from "../../../../api/services/deals";
import AccountCard from "../../../AccountCard";
import TransactionTableSortable from "../../../transactions/TransactionTableSortable";
import DealInformation from "../deal-components/DealInformation";
import EscrowTransactionsCards from "./EscrowTransactionCard";

export default function EscrowTransactions() {
  const navigate = useNavigate();
  const { code } = useParams();
  const { data: profiledata, isLoading: load1 }: any = useQuery(
    "getuser-id",
    getUserProfile
  );
  const { data: transactionData, isLoading: load2 }: any = useQuery(
    ["dealtransactions", code],
    dealtransaction
  );
  const { data: dealData, isLoading: load3 } = useQuery(
    ["deal", code],
    getDealsDataById,
    {
      onError: () => {
        navigate("/deals");
      },
    }
  );
  const Bankdetails = dealData?.data?.bankingDetails;

  const isBuyer =
    dealData?.data?.dealUsers?.find(
      (user: any) => user?.userId === profiledata?.data?.id
    )?.userType === "Buyer";

  if (load1 || load2 || load3) {
    return <LinearProgress color="primary" />;
  }

  return (
    <Box
      sx={{
        width: "97vw",
        minHeight: "83vh",
        my: "20px",
      }}
    >
      <Card
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: 2,
          py: 3,
          px: "1.5vw",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          color="#7643EB"
          sx={{ cursor: "pointer", mb: 2 }}
        >
          <IconButton
            size="medium"
            sx={{ mt: 0.5 }}
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
          </IconButton>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: "#7643EB", fontSize: "18px" }}
          >
            <Link underline="hover" color="#7643EB" href="/deals">
              Deals
            </Link>
            <Link underline="hover" color="#7643EB" href={`/deals/${code}`}>
              {code}
            </Link>
            <Link
              underline="hover"
              color="#7643EB"
              href={`/deals/${code}/truupe-account`}
            >
              Truupe Account
            </Link>
          </Breadcrumbs>
        </Box>

        <Divider sx={{ width: "100%", mb: 3 }} />

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 3,
            height: "100%",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="h4" align="left" sx={{}}>
              Account Summary
            </Typography>
            <AccountCard Bankdetails={Bankdetails} dealData={dealData} />
          </Box>

          <Box sx={{ flex: 2 }}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography variant="h4" align="left" sx={{ mb: 2 }}>
                Deal Information
              </Typography>
              {/* {(isBuyer || true) && (
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(`/deal-process/deals/${dealId}/request-received`);
                  }}
                  sx={{ mx: "2vw", marginBottom: "1vh" }}
                >
                  View Payment Request{" "}
                </Button>
              )} */}
            </Box>
            <DealInformation dealData={dealData} profiledata={profiledata} />
          </Box>
        </Box>
        <Box>
          <EscrowTransactionsCards
            dealData={dealData}
            dealId={code}
          ></EscrowTransactionsCards>
        </Box>

        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" align="left" sx={{ mb: 2 }}>
              Transactions
            </Typography>
            {/* <Button variant="outlined">
              <IosShareRoundedIcon sx={{ fontSize: 18, mr: 1 }} />
              Export
            </Button> */}
          </Box>
        </Box>

        <TransactionTableSortable
          dealData={dealData?.data}
          transactionData={transactionData}
        />
      </Card>
    </Box>
  );
}
