import { http } from "../http";

export const getUsersByemail = (params: any) => {
  return http.get("/users", { params: { search: params?.email } });
};

export const postDealInformation = (data: any) => {
  return http.post("/deals", data);
};

export const getDealsDataById = ({ queryKey }: any) => {
  return http.get(`/deals/${queryKey[1]}`);
};

export const postNotify = (data: any) => {
  return http.post("/deals/notify", data);
};

export const getUserDeals = (queryKey: any) => {
  return http.get("/deals/user-deals", {
    params: { ...queryKey },
  });
};

export const acceptDeal = ({ data, id }: any) => {
  return http.put(`/deals/${id}/accept`, data);
};

export const rejectDeal = ({ data, id }: any) => {
  return http.put(`/deals/${id}/reject`, data);
};

export const cancelDeal = ({ data, id }: any) => {
  return http.put(`/deals/${id}/cancel`, data);
};

export const publishDeal = ({ data, id }: any) => {
  return http.put(`/deals/${id}/publish`, data);
};

export const updateStatus = ({ data }: any) => {
  return http.put(`/deals/signer-status`, data);
};

// export const getBookingId = (id: any) => {
//   return http.get(`/booking/${id}`);
// };

export const createRazorpayOrder = (data: any) => {
  return http.post("/deal-payment/create-order", data);
};

export const verifyTransaction = (data: any) => {
  return http.post("/deal-payment/verify-transaction", data);
};

export const dealtransaction = ({ queryKey }: any) => {
  // console.log("this is deal code ", code);
  return http.get(`/deal-transaction/${queryKey[1]}`);
};

export const GetPaymentRequestsByDeal = ({ queryKey }: any) => {
  return http.get(`/payment-request`, {
    params: { dealCode: queryKey[1] },
  });
};

// Coupons
export const createCoupon = (data: any) => {
  return http.post(`/deal-coupons`, data);
};

export const getCoupons = (data: any) => {
  return http.get(`/deal-coupons`, data);
};

export const getCouponData = ({ id }: any) => {
  return http.get(`/deal-coupons/${id}`);
};

export const updateCoupon = ({ id, data }: any) => {
  return http.put(`/deal-coupons/${id}`, data);
};

export const deleteCoupon = ({ id }: any) => {
  return http.delete(`/deal-coupons/${id}`);
};

// export const deleteMultipleCoupons = (ids: number[]) => {
//   const deletePromises = ids.map((id) => deleteCoupon(id));
//   return Promise.all(deletePromises);
// };

export const deleteMultipleCoupons = (ids: number[]) => {
  const deletePromises = ids.map((id) => deleteCoupon({ id }));
  return Promise.all(deletePromises);
};
