import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdminTabs = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/dashboard")) {
      setValue(0);
    } else if (location.pathname.startsWith("/deals")) {
      setValue(1);
    } else if (location.pathname.startsWith("/entities")) {
      setValue(2);
    } else if (location.pathname.startsWith("/trustees")) {
      setValue(3);
    } else if (location.pathname.startsWith("/admins")) {
      setValue(4);
    } else if (location.pathname.startsWith("/users")) {
      setValue(5);
    } else if (location.pathname.startsWith("/coupons")) {
      setValue(6);
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/dashboard");
        break;
      case 1:
        navigate("/deals");
        break;
      case 2:
        navigate("/entities");
        break;
      case 3:
        navigate("/trustees");
        break;
      case 4:
        navigate("/admins");
        break;
      case 5:
        navigate("/users");
        break;
      case 6:
        navigate("/coupons");
        break;
      default:
        navigate("/dashboard");
        break;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{
            backgroundColor: "#7643EB",
            ".MuiTab-root": {
              marginLeft: "40px",
              color: "#C6B2F6",
              "&:hover": {
                color: "white",
              },
              "&.Mui-selected": {
                color: "white",
              },
            },
            ".MuiTabs-indicator": {
              backgroundColor: "white",
            },
          }}
        >
          <Tab
            label="Dashboard"
            {...a11yProps(0)}
            onClick={() => {
              navigate("/dashboard");
            }}
          />
          <Tab
            label="Deals"
            {...a11yProps(1)}
            onClick={() => {
              navigate("/deals");
            }}
          />
          <Tab
            label="Entities"
            {...a11yProps(2)}
            onClick={() => {
              navigate("/entities");
            }}
          />
          <Tab
            label="Trustees"
            {...a11yProps(3)}
            onClick={() => {
              navigate("/trustees");
            }}
          />
          <Tab
            label="Admins"
            {...a11yProps(4)}
            onClick={() => {
              navigate("/admins");
            }}
          />
          <Tab
            label="Users"
            {...a11yProps(5)}
            onClick={() => {
              navigate("/users");
            }}
          />
          <Tab
            label="Coupons"
            {...a11yProps(6)}
            onClick={() => {
              navigate("/coupons");
            }}
          />
        </Tabs>
      </Box>
    </Box>
  );
};

export default AdminTabs;
