import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Card, Divider, IconButton } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getDealsDataById,
  getSignerStatus,
  getUserProfile,
} from "../../../../api/services";
import SigneeCard from "./DealSignee";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function DealDocumentationSignee() {
  const { code }: any = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dealPublished, setDealPublished] = useState(false);
  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState(0);

  localStorage.setItem("dealcode", code);

  const handleDownload = () => {
    fetch(data?.signedDocUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "deal.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };

  const { data: profiledata }: any = useQuery("getuser-id", getUserProfile);
  const { data: dealData }: any = useQuery(["deal", code], getDealsDataById);

  const data = dealData?.data;
  const dealCreator = data?.dealUsers?.find(
    (user: any) => user?.userId === data?.createdBy?.id
  );
  const firstPerson = dealCreator?.userId === profiledata?.data?.id;

  const { mutate: status, isLoading: userLoader } = useMutation(
    getSignerStatus,
    {
      onSuccess: (res: any) => {
        toast.success("Status updated successfully");
        queryClient.invalidateQueries("deal");
        window.location.reload();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: Reloadstatus, isLoading: userLoaders } = useMutation(
    getSignerStatus,
    {
      onSuccess: (res: any) => {
        // toast.success("Status updated successfully");
        queryClient.invalidateQueries("deal");
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleStatus = () => {
    status({ data: { code: data?.code } });
  };

  useEffect(() => {
    Reloadstatus({ data: { code: data?.code } });
  }, [status, data?.code]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  return (
    <Box
      sx={{
        padding: "0px 15px",
        width: "97vw",
        minHeight: "83vh",
        marginTop: "20px",
      }}
    >
      <Card
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          minHeight: "83vh",
          py: "2.5vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "0px 15px",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            color="#7643EB"
            style={{ cursor: "pointer", marginBottom: "20px" }}
          >
            <IconButton
              size="small"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon fontSize="small" sx={{ color: "#7643EB" }} />
            </IconButton>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ color: "#7643EB", fontSize: "16px" }}
            >
              <Link underline="hover" color="#7643EB" href="/deals">
                Deals
              </Link>
              <Link underline="hover" color="#7643EB" href={`/deals/${code}`}>
                {code}
              </Link>
              <Link
                underline="hover"
                color="#7643EB"
                href={`/deals/${code}/documentationSignee`}
              >
                Deal Document
              </Link>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              padding: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownload}
              sx={{
                mt: -1.5,
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              Download
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleStatus}
              sx={{
                mt: -1.5,
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              Get Status
            </Button>
          </Box>
        </Box>

        <Divider sx={{ width: "100vw", ml: -8 }} />

        <Box display={"flex"} padding={"0px 0px"}>
          <Box
            sx={{
              width: "70%",
              padding: 2,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F9F9F9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  padding: "30px",
                  backgroundColor: "#F9F9F9",
                }}
              >
                <Card
                  sx={{
                    padding: "15px",
                    backgroundColor: "white",
                    overflow: "auto",
                    maxHeight: {
                      xs: "30vh",
                      sm: "55vh",
                      md: "70vh",
                      lg: "90vh",
                      xl: "110vh",
                    },
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      height: "100%",
                      marginLeft: 10,
                      marginTop: -25,
                    }}
                  >
                    <Document
                      file={data?.signedDocUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <div key={index}>
                          {index !== 0 && <Divider sx={{ my: 1.5 }} />}
                          <Page
                            pageNumber={index + 1}
                            className="pdf-page"
                            renderMode="canvas"
                            width={700}
                            loading="..."
                          />
                        </div>
                      ))}
                    </Document>
                  </div>
                </Card>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "32%",
              borderLeft: "1px solid #1212121A",
              padding: 2,
            }}
          >
            <SigneeCard
              data={data}
              accountOwner={firstPerson}
              needed={profiledata}
            />
          </Box>
        </Box>
      </Card>

      <style>{`
        .pdf-page {
          margin-bottom: -14px;
        }
      `}</style>
    </Box>
  );
}
