import { Box, Card, LinearProgress, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getCouponData } from "../../api/services/deals";
import { Coupon } from "../../utils/interfaces";
import CouponTop from "./CouponTop";

// const coupon: Coupon = {
//   id: "1",
//   name: "Winter Sale",
//   description: "Get 20% off on all items this winter!",
//   isActive: true,
//   code: "WINTER20",
//   usesPerCoupon: 100,
//   usesPerUser: 2,
//   startDate: "2024-11-01T00:00:00.000Z",
//   endDate: "2024-12-31T23:59:59.000Z",
//   type: CouponType.PERCENTAGE,
//   value: 20,
// };

export default function ViewCoupon() {
  const { couponId } = useParams();
  // const couponId = coupon?.id;
  const { data: couponData, isLoading: couponDataLoading } = useQuery<{
    data: Coupon;
  }>(["get-Coupons", couponId], () => getCouponData({ id: couponId }), {
    enabled: !!couponId,
  });
  const coupon = couponData?.data;
  if (couponDataLoading) {
    return <LinearProgress></LinearProgress>;
  }
  return (
    <>
      <Card sx={{ mt: 3, p: 2 }}>
        <CouponTop coupon={coupon} />
        <Box>
          <Typography variant="h6">COUPON CODE - {coupon?.id}</Typography>
        </Box>
      </Card>
    </>
  );
}
