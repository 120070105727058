import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Card,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getDealDetails } from "../../../../api/services";
import Status from "../../../../components/status/Status";
import DealDetails from "../deal-steps/DealDetails";
import DealStates from "../deal-steps/DealStates";
import DealTabs from "../deal-tabs/DealTabs";
import DealInfo from "./DealInfo";

export default function UserDeal() {
  const navigate = useNavigate();

  const { code } = useParams<{ code: string }>();

  const {
    data: dealData,
    isLoading,
    error,
  } = useQuery(["code", code], () => getDealDetails(code), {
    enabled: !!code,
  });

  //  isLoading then show a Linear Progress
  if (isLoading) return <LinearProgress />;

  const data = dealData?.data;

  const dealCreator = data?.dealUsers?.find(
    (user: any) => user?.userId === data?.createdBy?.id
  );

  const role = localStorage.getItem("Role");

  localStorage.setItem("DealPresentState", data?.state);
  localStorage.setItem("DealPresentStatus", data?.status);
  localStorage.setItem("DealCode", data?.code);
  // console.log(role);

  // const firstPerson = dealCreator?.userId === profiledata?.data?.id;

  return (
    <>
      <Box
        sx={{
          padding: "0px 20px",
          width: "98vw",
          minHeight: "83vh",
          marginTop: "20px",
        }}
      >
        <Card
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "8px",
            minHeight: "83vh",
            py: "3vh",
            px: "1.5vw",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            color={"#7643EB"}
            style={{ cursor: "pointer" }}
          >
            <IconButton
              size="medium"
              sx={{ mt: 0.5 }}
              onClick={() => {
                navigate("/deals", { replace: true });
              }}
            >
              <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
            </IconButton>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ color: "#7643EB", fontSize: "18px" }}
            >
              <Link underline="hover" color="#7643EB" href="/deals">
                Deals
              </Link>
              <Link underline="hover" color="#7643EB" href="/deals">
                {data?.code}
              </Link>
            </Breadcrumbs>
          </Box>
          <Box display="flex" justifyContent={"space-between"}>
            <Typography variant="h5" my="10px" ml={2}>
              {data?.code}
            </Typography>
            <Status variant={data?.status} />
            {/* <Box>
              {data?.dealSignatures &&
                (() => {
                  const signatures = data.dealSignatures;
                  let count = 0;

                  for (const signature of signatures) {
                    if (signature?.status === "Completed") {
                      count++;
                    }
                  }

                  if (count == 2 || count == 3) {
                    return <Status variant={`Truupe ${data.status}`} />;
                  } else {
                    return (
                      <Box>
                        {data?.status === "Fee Pending" ? (
                          <Status variant={`Service Payment`} />
                        ) : data?.status === "Funds Awaited" ? (
                          <Status variant={"Funds Awaited"} />
                        ) : data?.status === "Payment Verification" ? (
                          role === "Admin" ? (
                            <Status variant={"Under Verification"} />
                          ) : (
                            <Status variant={"Verify Payment"} />
                          )
                        ) : (
                          <>
                            {" "}
                            <Status variant={data?.status} />
                          </>
                        )}
                      </Box>
                    );
                  }
                })()}
            </Box> */}
          </Box>

          <Box sx={{ ml: 2 }}>
            <DealTabs data={data} />
          </Box>
          <Box display={"flex"}>
            <Box
              sx={{
                width: "25vw",
                borderRight: "1px solid #1212121A",
                marginBottom: "25px",
              }}
            >
              <DealInfo data={data} accountOwner={data?.dealUsers?.[0]} />
            </Box>
            <Box sx={{ width: "75vw" }}>
              <DealDetails data={data} accountOwner={data?.dealUsers?.[0]} />
            </Box>
          </Box>
        </Card>
      </Box>
      <DealStates data={data} />
    </>
  );
}
