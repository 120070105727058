import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DealTabs({ data, accountOwner }: any) {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    let targetRoute;

    switch (newValue) {
      case 1:
        targetRoute =
          data?.status === "Deal Accepted"
            ? `/deals/${data?.code}/documentation`
            : `/deals/${data?.code}/documentationSignee`;
        break;
      case 2:
        targetRoute = `/deals/${data?.code}/truupe-account`;
        break;
      // case 3:
      //   targetRoute = `/deals/${data?.code}/request-received`;
      //   break;
      default:
        targetRoute = null;
    }

    if (targetRoute) {
      navigate(targetRoute, { replace: true });
    }
  };

  return (
    <Box sx={{ width: "100%", transformOrigin: "top left" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Details" {...a11yProps(0)} sx={{ fontSize: "12px" }} />
          <Tab
            label={
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "12px" }}
              >
                Documentation
                {(data?.state === "Deal Invitation" ||
                  data?.state === "Deal Rejected") && (
                  <LockOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                )}
              </Box>
            }
            {...a11yProps(1)}
            disabled={
              data?.state === "Deal Invitation" ||
              data?.state === "Deal Rejected"
            }
          />
          <Tab
            label={
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "12px" }}
              >
                Truupe Account
                {(data?.state === "Deal Invitation" ||
                  data?.state === "Deal Documentation") && (
                  <LockOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                )}
              </Box>
            }
            {...a11yProps(2)}
            disabled={
              data?.state === "Deal Invitation" ||
              data?.state === "Deal Documentation"
            }
          />
          {/* <Tab
            label={
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "12px" }}
              >
                View Payment Request
                {(data.state === "Deal Invitation" ||
                  data?.state === "Deal Documentation" ||
                  data?.status === "Funds Awaited") && (
                  <LockOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                )}
              </Box>
            }
            {...a11yProps(3)}
            disabled={
              data?.state === "Deal Invitation" ||
              data?.state === "Deal Documentation" ||
              data?.status === "Funds Awaited"
            }
          /> */}
        </Tabs>
      </Box>
      {/* <CustomTabPanel value={value} index={0}>
        Details
      </CustomTabPanel> */}
      {/* <CustomTabPanel value={value} index={1}>
        Escrow Account
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Documentation
      </CustomTabPanel> */}
    </Box>
  );
}
