import styled from "@emotion/styled";
import { Box, Card, Typography } from "@mui/material";
import { dealAmount, Icons } from "../../../../assets";

export default function EscrowTransactionsCards({ dealData }: any) {
  function formatNumberToIndianSystem(number: number) {
    return new Intl.NumberFormat("en-IN").format(number);
  }
  const overview = dealData?.data?.overview;

  const cardData = [
    {
      label: "Total Deal Amount",
      amount: overview?.totalAmount,
      icon: dealAmount,
    },
    {
      label: "Total Credit",
      amount: overview?.credit,
      icon: Icons.PaymentReceived,
    },
    {
      label: "Total Balance",
      amount: overview?.balance,
      icon: Icons.PaymentReceived,
    },
    {
      label: "Total Debit",
      amount: overview?.debit,
      icon: Icons?.Paymnetsent,
    },
  ];

  return (
    <Container>
      {cardData?.map(({ label, amount, icon }, index) => (
        <StyledCard key={index}>
          <CardContent>
            <IconBox>
              <img
                src={icon}
                alt={label}
                style={{ height: "35px", width: "35px" }}
              />
            </IconBox>
            <Box display="flex" flexDirection="column">
              <Typography variant="h1" sx={{ fontWeight: 500 }}>
                {"  ₹  "}
                {formatNumberToIndianSystem(amount)}
              </Typography>
              <Typography variant="h6" sx={{ opacity: 0.6 }}>
                {label}
              </Typography>
            </Box>
          </CardContent>
        </StyledCard>
      ))}
    </Container>
  );
}

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid #1212121a;
  border-bottom: 1px solid #1212121a;
  padding: 20px;
  margin: 15px 0;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  background-color: #f3efff;
  max-height: 26vh;
  min-height: 10vh;
  border: 1px solid #1212121a;
  border-radius: 5px;
  width: 21vw;
  padding: 2vh 1vw;
`;

const CardContent = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: start;
`;

const IconBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 20%;
  background-color: rgba(106, 27, 154, 0.1);
`;
