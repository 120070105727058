import { ArrowDropDown, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getDeals } from "../../api/services";
import Status from "../../components/status/Status";
import NewTable from "../../components/table/NewTable";

const Deals: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const Role = localStorage.getItem("Role");
  const [clearFilter, setClearFilters] = useState(false);
  const filters = localStorage.getItem("filters")?.split(",");
  const [filterStatus, setFilterStatus] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(0);
  const { data: dealData, isLoading } = useQuery(
    [
      "getDealData",
      {
        search: searchTerm,
        status: filterStatus,
        limit: limit,
        offset: page * limit,
      },
    ],
    getDeals
  );

  useEffect(() => {
    if (filters) {
      setFilterStatus(filters);
    } else {
      if (Role === "Admin") {
        setFilterStatus([]);
      } else if (Role === "Trustee") {
        if (clearFilter) {
        } else {
          setFilterStatus(["Verification Pending", "Payment Verification"]);
        }
      }
    }
  }, [dealData]);

  const statusOptions: string[] = [
    "Deal Created",
    "Invitation Issued",
    "Deal Accepted",
    "Deal Rejected",
    "Document Published",
    "Signature Pending",
    "Verification Pending",
    "Deal Cancelled",
    "Fee Pending",
    "Funds Awaited",
    "Funds Received",
    "Payment Verification",
    "Deal Completed",
  ];

  const handleSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 600); // 600ms delay

  const handleStatusChange = (event: any) => {
    localStorage.setItem("filters", event.target.value);
    const value = event.target.value;
    setFilterStatus(typeof value === "string" ? value.split(",") : value);
  };

  const clearFilters = () => {
    setFilterStatus([]);
    localStorage.removeItem("filters");
    setClearFilters(true);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value));
    setPage(0);
  };

  const formatAmountWithCommas = (amount: string) => {
    const num = parseFloat(amount.replace(/[^0-9.-]+/g, ""));
    if (!isNaN(num)) {
      return new Intl.NumberFormat("en-IN").format(num);
    } else {
      return amount;
    }
  };

  const handleCellClick = (code: string, status: string) => {
    navigate(`/deals/${code}`);
  };

  const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const column = [
    {
      title: "Deal ID",
      dataIndex: "code",
      key: "code",
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.code, row?.status);
      },
    },
    {
      title: "Deal Title",
      dataIndex: "title",
      key: "title",
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.code, row?.status);
      },
    },
    {
      title: "Seller Entity Name",
      dataIndex: "seller",
      key: "seller",
      render: (colData: any, row: any) => {
        const seller = row?.dealUsers.find(
          (i: any) => i?.userType === "Seller"
        );
        return seller?.entity?.companyType === "INDIVIDUAL" ||
          seller?.entity === null
          ? seller?.user?.name || "NA"
          : seller?.entity?.companyName;
        // return row?.dealUsers.find((i: any) => i?.userType === "Seller")?.entity
        //   ?.companyName;
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.code, row?.status);
      },
    },
    {
      title: "Buyer Entity Name",
      dataIndex: "buyerNameOrCompany",
      key: "buyerNameOrCompany",
      render: (colData: any, row: any) => {
        const buyer = row?.dealUsers.find((i: any) => i?.userType === "Buyer");
        return buyer?.entity?.companyType === "INDIVIDUAL" ||
          buyer?.entity === null
          ? buyer?.user?.name || "NA"
          : buyer?.entity?.companyName;
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.code, row?.status);
      },
    },

    {
      title: "Deal Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (colData: any, row: any) => {
        return formatDate(colData);
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.code, row?.status);
      },
    },
    {
      title: "Deal Amount",
      dataIndex: "amount",
      key: "amount",
      render: (colData: any, row: any) => {
        return formatAmountWithCommas(colData);
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.code, row?.status);
      },
    },
    {
      title: "Deal Status",
      dataIndex: "status",
      key: "status",
      render: (colData: any, row: any) => {
        return <Status variant={colData} />;
      },
      onCellClick: (colData: any, row: any) => {
        return handleCellClick(row?.code, row?.status);
      },
    },
    {
      title: "Next Step",
      dataIndex: "nextStep",
      key: "nextStep",
      render: (colData: any, row: any) => {
        const dealSignatures = row?.dealSignatures;
        const Trustee = dealSignatures.find(
          (obj: any) =>
            obj.preSignResponse.displayName === "MITCON Trustee Escrow Agent"
        );

        const isTrusteeSigned = Trustee?.status === "Completed" ? true : false;
        return (
          <Box>
            <Box>
              {row?.status === "Invitation Issued" ? (
                <Status variant={`Deal Invitation Sent`} />
              ) : row?.status === "Deal Rejected" ? (
                <Status variant={`Deal Cancelled`} />
              ) : row?.status === "Verification Pending" ? (
                isTrusteeSigned ? (
                  <>
                    {row === "Trustee" ? (
                      <>
                        {" "}
                        <Status variant={"Truupe Verification Pending"} />
                      </>
                    ) : (
                      <>
                        {" "}
                        <Status variant={"Needs your Signature"} />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {row === "Trustee" ? (
                      <>
                        <Status variant={"Needs your Signature"} />
                      </>
                    ) : (
                      <>
                        <Status variant={"Verification Pending"} />
                      </>
                    )}
                  </>
                )
              ) : row?.status === "Signature Pending" ? (
                <Status variant={`Needs other Signatures`} />
              ) : row?.status === "Fee Pending" ? (
                <Status variant={`Buyer to Pay Platform Fee`} />
              ) : row?.status === "Funds Awaited" ? (
                <Status variant={"Funds yet to be credited"} />
              ) : row?.status === "Funds Received" ? (
                <Status variant={`Account credited`} />
              ) : row?.status === "Payment Verification" ? (
                Role === "Admin" ? (
                  <Status variant={"Payment under Verification"} />
                ) : (
                  <Status variant={"Verify Payment"} />
                )
              ) : (
                <>
                  {" "}
                  <Status variant={row?.status} />
                </>
              )}
            </Box>
          </Box>
        );
      },
    },
  ];

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        marginTop: "20px",
        marginBottom: "20px",
        padding: "10px",
        height: "84vh",
      }}
    >
      <Grid item xs={12}>
        <Card
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #1212121A",
            borderRadius: "10px",
            opacity: 1,
            padding: "20px",
            marginBottom: "20px",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            marginBottom="20px"
          >
            <Grid item>
              <TextField
                placeholder="Search By Deal ID, Deal Title, Seller Entity Name, Buyer Entity Name"
                variant="outlined"
                size="small"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small">
                        <Search fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    width: "500px",
                    height: "40px",
                    boxShadow: "0px 6px 14px #36408D08",
                    border: "1px solid #2B2D221C",
                    borderRadius: "10px",
                    opacity: 1,
                    fontSize: "16px",
                    fontFamily: "Mundial, sans-serif",
                  },
                }}
                inputProps={{
                  style: {
                    fontFamily: "Mundial, sans-serif",
                    fontSize: "16px",
                  },
                  placeholderStyle: {
                    fontSize: "12px",
                    fontFamily: "Mundial, sans-serif",
                  },
                }}
              />

              <Select
                multiple
                value={filterStatus}
                onChange={handleStatusChange}
                variant="outlined"
                IconComponent={ArrowDropDown}
                displayEmpty
                renderValue={(selected) =>
                  selected.length > 0 ? (
                    <Box display="flex" alignItems="center">
                      Status
                      <Box
                        component="span"
                        borderRadius="50%"
                        color="#FFFFFF"
                        fontSize="10px"
                        marginLeft="5px"
                        width="15px"
                        height="15px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                          backgroundColor: theme.palette.primary.main,
                        }}
                      >
                        {selected.length}
                      </Box>
                    </Box>
                  ) : (
                    "Status"
                  )
                }
                style={{
                  width: "130px",
                  height: "40px",
                  border: "1px solid #1212121A",
                  borderRadius: "10px",
                  opacity: 0.6,
                  boxShadow: "0px 6px 14px #36408D08",
                  fontSize: "14px",
                  fontFamily: "Mundial, sans-serif",
                  color: "#1D1D1D",
                  marginLeft: "10px",
                }}
                inputProps={{
                  style: {
                    fontFamily: "Mundial, sans-serif",
                    fontSize: "14px",
                  },
                }}
                SelectDisplayProps={{
                  style: {
                    fontFamily: "Mundial, sans-serif",
                    fontSize: "12px",
                  },
                }}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={filterStatus.indexOf(option) > -1} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>

              {filterStatus.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={clearFilters}
                  sx={{
                    height: "38px",
                    marginLeft: "10px",
                    borderRadius: "8px",
                  }}
                >
                  Clear Filters
                </Button>
              )}
            </Grid>

            <Grid item></Grid>
          </Grid>

          {isLoading ? (
            <LinearProgress></LinearProgress>
          ) : (
            <>
              <NewTable
                columns={column}
                headAlign={"center"}
                tableAlign={"center"}
                dataSource={dealData?.data}
                pagination={{
                  page: page,
                  rowsPerPage: limit,
                  count: dealData?.count,
                  onPageChange: handlePageChange,
                  onRowsPerPageChange: handleRowsPerPageChange,
                }}
              />
            </>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default Deals;
